// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import MetaState from './MetaState';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { getDefaultAxiosConfig } from '../../utils/axiosConfig';

export default class MetaBloc {
    private readonly stateSubject: BehaviorSubject<MetaState>;
    private weClapp: WeClappService;
    private wooCommerce: WooCommerceService;

    constructor() {
        this.stateSubject = new BehaviorSubject( new MetaState() );
        this.weClapp      = new WeClappService();
        this.wooCommerce  = new WooCommerceService();
    }

    removeNewTags = async () => {
        this.setLoadingState( 'removeNewTags', true );
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/removeNewTagFromAllProducts` ),
            getDefaultAxiosConfig()
        );

        toast.success( `🟢️ Removed NEU-Tag from all articles.` );
        console.log( result?.data );

        this.setLoadingState( 'removeNewTags', false );
    };

    fixBaseProductsWithNoImages = async () => {
        this.setLoadingState( 'fixBaseProductsWithNoImages', true );
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/fixNoMainProductImageWithVariantImages` ),
            getDefaultAxiosConfig()
        );
        let res                   = result?.data;
        toast.success( `🟢️ Fixed ${res?.length ?? 0} product with no images.` );
        console.log( res );

        this.setLoadingState( 'fixBaseProductsWithNoImages', false );
    };

    importSubBrands = async () => {
        this.setLoadingState( 'importSubBrands', true );
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/importSubBrands` ),
            getDefaultAxiosConfig()
        );
        let newSubBrands          = result?.data;
        toast.success( `🟢️ ${newSubBrands.length} new sub brands imported.` );
        this.setLoadingState( 'importSubBrands', false );
    };

    setSubBrands = async () => {
        this.setLoadingState( 'setSubBrands', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/setSubBrands` ),
            getDefaultAxiosConfig()
        );
        let updatedArticles       = result?.data;
        toast.success( `🟢️ Sub-Brands for ${updatedArticles.length} articles set.` );
        console.log( updatedArticles );

        this.setLoadingState( 'setSubBrands', false );
    };

    updateBrandCategoryOrders = async () => {
        this.setLoadingState( 'updateBrandCategoryOrders', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/setBrandCategoryOrders` ),
            getDefaultAxiosConfig()
        );
        let orderedCategories     = result?.data;
        toast.success( `🟢️ Product menu_order updated for ${orderedCategories.length} categories.` );
        console.log( orderedCategories );

        this.setLoadingState( 'updateBrandCategoryOrders', false );
    };

    addSizeGuideLinksToDl = async () => {
        this.setLoadingState( 'addSizeGuideLinksToDl', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/setSizeGuideLinksToDl` ),
            getDefaultAxiosConfig()
        );
        let updatedArticles       = result?.data;
        toast.success( `🟢️ Size guide links added to ${updatedArticles.length} articles.` );
        console.log( updatedArticles );

        this.setLoadingState( 'addSizeGuideLinksToDl', false );
    };

    setFilterColors = async () => {
        this.setLoadingState( 'setFilterColors', true );
        // repeat sending the request until no entries are returned from the API
        let returnedArticles = [];
        let failSafe         = 1;
        do {
            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/transformColorAttributeToFilterColor?page=${failSafe}` ),
                getDefaultAxiosConfig()
            );
            returnedArticles          = result?.data;
            toast.success( `🟢️ Set filer colors for ${returnedArticles.length} articles. (Page ${failSafe})` );
            console.log( returnedArticles );
            failSafe++;
        } while ( returnedArticles.length > 0 && failSafe < 50 );

        this.setLoadingState( 'setFilterColors', false );
    };

    deleteEmptyVariableProducts = async () => {
        this.setLoadingState( 'deleteEmptyVariableProducts', true );

        let totalDeletedProducts: any[] = [];
        let lastFoundProducts           = 0;
        let failSafe                    = 0;
        let offset                      = 0;

        do {
            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `products/deleteEmptyVariableProducts?offset=${offset}` ),
                getDefaultAxiosConfig()
            );
            let response              = result?.data;
            let deletedArticles       = response?.empty_products;
            lastFoundProducts         = response?.found_products;

            offset += lastFoundProducts - deletedArticles.length;

            toast.success( `🟢️ Deleted ${deletedArticles.length} empty variable products.` );
            console.log( deletedArticles );
            totalDeletedProducts = totalDeletedProducts.concat( deletedArticles );
            failSafe++;
        }
        while ( lastFoundProducts > 0 && failSafe < 50 );

        toast.success( `🟢️ Deleted TOTAL ${totalDeletedProducts.length} empty variable products.` );

        this.setLoadingState( 'deleteEmptyVariableProducts', false );
    };

    setCustomizableAttribute = async () => {
        this.setLoadingState( 'setCustomizableAttribute', true );

        let returnedArticles = [];
        let failSafe         = 1;
        do {
            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/setCustomizableAttribute?page=${failSafe}` ),
                getDefaultAxiosConfig()
            );
            returnedArticles          = result?.data;
            toast.success( `🟢️ Set customizable attribute for ${returnedArticles.length} articles. (Page ${failSafe})` );
            console.log( returnedArticles );
            failSafe++;
        } while ( returnedArticles.length > 0 && failSafe < 50 );

        this.setLoadingState( 'setCustomizableAttribute', false );
    };

    setPrivateLabelAttribute = async () => {
        this.setLoadingState( 'setPrivateLabelAttribute', true );

        let returnedArticles = [];
        let failSafe         = 1;
        do {
            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/setPrivateLabelAttribute?page=${failSafe}` ),
                getDefaultAxiosConfig()
            );
            returnedArticles          = result?.data;
            toast.success( `🟢️ Set private label attribute for ${returnedArticles.length} articles. (Page ${failSafe})` );
            console.log( returnedArticles );
            failSafe++;
        } while ( returnedArticles.length > 0 && failSafe < 50 );

        this.setLoadingState( 'setPrivateLabelAttribute', false );
    };

    setVariationOptions = ( variationOptions: any = {}, updatedString: string = '' ) => {
        let currentState: MetaState = this.currentState;

        if ( !_.isNull( variationOptions ) ) {
            currentState.variationAttributes.options = variationOptions;
        }


        if ( !_.isEmpty( updatedString ) ) {
            currentState.variationAttributes.lastUpdated = updatedString;
        }

        this.pushState( currentState );

        return currentState;
    };

    setSettings = ( key: string, value: any ) => {
        let currentState: MetaState     = this.currentState;
        currentState.settingsState[key] = value;
        this.pushState( currentState );

        return currentState;
    };

    setLoadingState = ( key: string, loading: boolean = true ) => {
        let currentState: MetaState     = this.currentState;
        currentState.loadingStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    setOpenState = ( key: string, loading: boolean = true ) => {
        let currentState: MetaState  = this.currentState;
        currentState.openStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    get currentState() {
        return this.getStateSubject().getValue();
    }

    pushState = ( newState: MetaState ) => {
        this.getStateSubject().next( newState );
        return newState;
    };

    getStateSubject = () => {
        return this.stateSubject;
    };
}
