import React, { Component, createRef } from 'react';
import { GrWordpress, IoCogOutline } from 'react-icons/all';
import ProductsBloc from '../../../blocs/ProductsBloc/ProductsBloc';
import ProductsState from '../../../blocs/ProductsBloc/ProductsState';
import { BlocBuilder } from 'react-blocbuilder';
import * as _ from 'lodash';
import Button, { ButtonStyle } from '../../buttons/Button';
import { ReactComponent as WeClappLogo } from '../../../images/logos/weclapp-ccolor.svg';

import "./ProductsPage.scss";
import SegmentToggle from '../../SegmentToggle/SegmentToggle';
import SettingsBox from '../../SettingsBox/SettingsBox';
import MessageBox, { MessageBoxType } from '../../MessageBox/MessageBox';
import { toast } from 'react-toastify';

class ProductsPage extends Component<any, any> {
    private bloc: ProductsBloc;
    private articleNrRef: React.RefObject<any> = createRef();

    constructor( props: any ) {
        super( props );

        this.bloc = new ProductsBloc();
    }

    handleSubmit = async ( e: React.MouseEvent<HTMLButtonElement> ) => {
        e.preventDefault();

        if ( this.bloc.currentState.settingsState.importMode === "batch" ) {
            let articleNumbers = this.articleNrRef.current.value.split( '\n' );

            if ( this.bloc.currentState.settingsState.transferMode === 'delete' ) {
                await this.bloc.deleteBatchArticles( articleNumbers );
                return;
            }

            await this.bloc.syncBatchArticles( articleNumbers, this.bloc.currentState.settingsState.handleExistingProducts );
            return;
        }

        /**
         * If `articleNumber` is validated, sync data into WooCommerce.
         */
        if ( this.bloc.currentState.weClappData ) {

            if ( this.bloc.currentState.settingsState.transferMode === 'delete' ) {
                let res: any = await this.bloc.deleteArticle( this.bloc.currentState.weClappData );

                if ( res?.response?.message === 'OK' ) {
                    toast.success( `🔥 Artikel ${this.bloc.currentState.weClappData.articleNumber} wurde gelöscht.` );
                    return;
                }

                toast.error( `❌ Artikel ${this.bloc.currentState.weClappData.articleNumber} löschen fehlgeschlagen!` );

                return;
            }

            await this.bloc.syncArticle( this.bloc.currentState.weClappData );
            return;
        }

        /**
         * Fetch data from WeClapp
         */
        let articleNumber = this.articleNrRef.current.value;

        let article = await this.bloc.loadArticleData(
            articleNumber,
            this.bloc.currentState.settingsState.fetchByMode,
            this.bloc.currentState.settingsState.articleType,
        );

        let realArticleNumber = article?.articleNumber ?? article?.variantArticleNumber ?? 0;

        await this.bloc.loadWooCommerceProduct( realArticleNumber );
    };

    handleLoadWooCommerceData = async () => {
        let articleNumber = this.articleNrRef.current.value;
        await this.bloc.loadWooCommerceProduct( articleNumber );
    };

    render() {
        return (
            <BlocBuilder
                initialValue={new ProductsState()}
                subject={this.bloc.getStateSubject()}
                builder={( currentState ) => {
                    return ( <div>
                        <div className="flex  flex-row">
                            <h2 className="text-3xl  font-bold">Produkte synchr.</h2>

                            <div className="ml-auto">
                                <button className="flex  flex-row  items-center  |  space-x-3  |  cursor-pointer"
                                        onClick={() => this.bloc.setOpenState( 'settings', !( currentState.data.openStates?.settings ?? true ) )}>
                                    <span className="display-inlineblock  |  text-blue-600">
                                        {!( currentState.data.openStates?.settings ?? true ) && "Einstellungen"}
                                        {( currentState.data.openStates?.settings ?? true ) && "Schließen"}
                                    </span>
                                    <IoCogOutline size={30} color={"#3b82f6"}/>
                                </button>
                            </div>
                        </div>

                        {( currentState.data.openStates?.settings ?? true ) &&
                         <div className="py-3  px-4  |  mt-3  |  bg-gray-200  |  rounded-lg">
                             <h3 className="text-xl  font-medium  |  mb-4">
                                 Einstellungen
                             </h3>

                             <div className="flex  flex-row  |  space-x-5  |  mb-2">
                                 {currentState.data.settingsState.transferMode !== 'delete' &&
                                  <SettingsBox
                                      title="Artikel-Modus"
                                      desc="Welche Art von Artikeln wird versucht zu synchronisieren?"
                                      className="border-r  border-gray-300"
                                  >
                                      <SegmentToggle
                                          options={{
                                              'variantArticle': 'Varianten-Artikel',
                                              'article':        'Einzel-Artikel',
                                          }}
                                          value={currentState.data.settingsState.articleType} id={'articleType'}
                                          onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'articleType', val ); }}
                                      />
                                  </SettingsBox>}

                                 {currentState.data.settingsState.transferMode !== 'delete' &&
                                  <SettingsBox
                                      title="Existierende Produkte"
                                      desc="Was soll mit Produkten passieren die bereits im Shop-System existieren?"
                                      className="border-r  border-gray-300"
                                  >
                                      <SegmentToggle
                                          options={{
                                              'skip':   'Überspringen',
                                              'update': 'Aktualisieren',
                                          }}
                                          value={currentState.data.settingsState.handleExistingProducts} id={'existingProducts'}
                                          onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'handleExistingProducts', val ); }}
                                      />
                                  </SettingsBox>}

                                 <SettingsBox
                                     title="Import-Modus"
                                     desc="Wechsle zwischen dem Einzel- & Massen-Import-Modus."
                                     className="border-r  border-gray-300"
                                 >
                                     <SegmentToggle
                                         options={{
                                             'single': 'Einzeln',
                                             'batch':  'Batch',
                                         }}
                                         value={currentState.data.settingsState.importMode} id={'importMode'}
                                         onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'importMode', val ); }}
                                     />
                                 </SettingsBox>

                                 <SettingsBox
                                     title="Transfer-Modus"
                                     desc="Wechsle zwischen dem Erstellen/Aktualisieren und Entfernen von Produkten.."
                                 >
                                     <SegmentToggle
                                         options={{
                                             'create': 'Erstellen/Akt.',
                                             'delete': 'Löschen',
                                         }}
                                         value={currentState.data.settingsState.transferMode} id={'transferMode'}
                                         onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'transferMode', val ); }}
                                     />
                                 </SettingsBox>
                             </div>
                         </div>}

                        <hr className="my-6"/>

                        <div className="mb-6">
                            <h3 className="text-xl  font-medium  |  mb-4">Gebe die gewünschte Artikel-Nr. ein</h3>

                            <div
                                className="input-group  |  flex  flex-col  |  px-3  py-2  |  border-2  border-gray-300  |  rounded-md  |  bg-white  |  focus-within:border-gray-400">
                                <div className="flex  flex-row  |  space-x-4">
                                    <div className="flex  flex-col  |  w-full">
                                        <label className="relative  |  text-sm  font-medium  text-gray-500" htmlFor="singleArticleNumber"
                                               style={{ top: '-1px' }}>
                                            {this.bloc.currentState.settingsState.fetchByMode === "sku" && "Artikel-Nr."}
                                            {this.bloc.currentState.settingsState.fetchByMode === "id" && "WeClapp-ID"}
                                        </label>

                                        {currentState.data.settingsState.importMode === "single" &&
                                         <input type="text" className="focus:outline-none  font-medium  |  w-full"
                                                defaultValue={"STSU806C2502X"}
                                                onChange={() => {
                                                    this.bloc.setWooCommerceData( null );
                                                    this.bloc.setWeClappData( null );
                                                }}
                                                id="singleArticleNumber" ref={this.articleNrRef}/>
                                        }

                                        {currentState.data.settingsState.importMode === "batch" &&
                                         <textarea className="focus:outline-none  font-medium  |  w-full"
                                                   defaultValue={"STSU806C2502X\nAAASU806C2502X"}
                                                   onChange={() => {
                                                       this.bloc.setWooCommerceData( null );
                                                       this.bloc.setWeClappData( null );
                                                   }}
                                                   id="batchArticleNumbers" ref={this.articleNrRef}>
                                         </textarea>
                                        }
                                    </div>

                                    <div className="ml-auto  flex-shrink-0  |  flex  flex-row  space-x-3  items-center  |">
                                        <SegmentToggle
                                            options={( currentState.data.settingsState.transferMode === 'delete' ) ? { 'sku': 'SKU' } : {
                                                'sku': 'SKU',
                                                'id':  'ID',
                                            }}
                                            value={currentState.data.settingsState?.fetchByMode ?? 'sku'} id={'fetchByMode'}
                                            onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'fetchByMode', val ); }}
                                            className="bg-gray-100"
                                        />

                                        <Button
                                            style={( !currentState.data.weClappData && currentState.data.settingsState.importMode !== "batch" )
                                                   ? ButtonStyle.Secondary
                                                   : ( ( currentState.data.loadingStates?.wooCommerceData ?? false ) )
                                                     ? ButtonStyle.Disabled : ( currentState.data.settingsState.transferMode === 'delete' )
                                                                              ? ButtonStyle.Negative : ButtonStyle.Default}
                                            onClick={( e ) => this.handleSubmit( e )}
                                            loading={( currentState.data.loadingStates?.articleNumber === true )}>
                                            {( !currentState.data.weClappData && currentState.data.settingsState.importMode !== "batch" ) && "Validieren"}
                                            {( currentState.data.weClappData || currentState.data.settingsState.importMode === "batch" ) && "Synchron."}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex  flex-row  |  space-x-4">
                            <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                    <WeClappLogo width={20} color={"#12da9d"}/>
                                    <span>WeClapp Daten</span>
                                </h3>

                                {/* Empty State */}
                                {!currentState.data.weClappData &&
                                 <div>
                                     <div className="font-medium  text-gray-500  |  mb-4">
                                         Noch keine Daten geladen
                                     </div>
                                 </div>}

                                { ( ( currentState.data.weClappData?.length ?? 0 ) === 0 ) &&
                                 <div>
                                     <MessageBox>
                                         Keine Daten gefunden
                                     </MessageBox>
                                 </div>}

                                {/* Data Table */}
                                {( currentState.data.weClappData && _.isPlainObject( currentState.data.weClappData ) ) &&
                                 <div className="">
                                     <div className="flex  flex-row  space-x-5  |  font-bold">
                                         <div className="text-left  |  w-36">Key</div>
                                         <div className="text-left">Value</div>
                                     </div>
                                     <div>
                                         {_.toPairs( currentState.data.weClappData ).map( ( value: any, index: number ) =>
                                             <div key={value[0]} className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                            ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                            : 'py-4  px-3'}  |  rounded`}>
                                                 <div className="font-bold  |  w-36  |  flex-shrink-0">{value[0]}</div>
                                                 <div className="break-all  |  font-mono">
                                                     <pre>{JSON.stringify( value[1], null, 2 )}</pre>
                                                 </div>
                                             </div> )}
                                     </div>
                                 </div>
                                }
                            </div>

                            <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                    <GrWordpress size={20} color={"#1d4ed8"}/>
                                    <span>WooCommerce Daten</span>
                                </h3>

                                {/* Empty State */}
                                {!currentState.data.wooCommerceData &&
                                 <div>
                                     <div className="font-medium  text-gray-500  |  mb-4">
                                         Noch keine Daten geladen
                                     </div>

                                     <Button
                                         style={( currentState.data.currentArticleNumber ) ? ButtonStyle.Default : ButtonStyle.Disabled}
                                         loading={( currentState.data.loadingStates?.wooCommerceData === true )}
                                         onClick={( e ) => this.handleLoadWooCommerceData()}>
                                         Neu laden
                                     </Button>
                                 </div>}

                                {/* Data Table */}
                                {currentState.data.wooCommerceData &&
                                 <div className="">
                                     {_.isObject( currentState.data.wooCommerceData ) &&
                                      <div>
                                          <div className="flex  flex-row  space-x-5  |  font-bold">
                                              <div className="text-left  |  w-36">Key</div>
                                              <div className="text-left">Value</div>
                                          </div>

                                          {_.toPairs( currentState.data.wooCommerceData ).map( ( value: any, index: number ) =>
                                              <div key={value[0]} className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                             ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                             : 'py-4  px-3'}  |  rounded`}>
                                                  <div className="font-bold  |  w-36  |  flex-shrink-0">{value[0]}</div>
                                                  <div className="break-all  |  font-mono">
                                                      <pre>{JSON.stringify( value[1], null, 2 )}</pre>
                                                  </div>
                                              </div> )
                                          }
                                      </div>}

                                     {_.isString( currentState.data.wooCommerceData ) &&
                                      <MessageBox
                                          type={MessageBoxType.Error}>
                                          {currentState.data.wooCommerceData}
                                      </MessageBox>}
                                 </div>
                                }
                            </div>
                        </div>
                    </div> );
                }}/>
        );
    }
}

export default ProductsPage;
