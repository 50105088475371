// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import ProductsState, {
    ProductSettingsArticleType,
    ProductSettingsFetchByMode,
    ProductSettingsHandleExistingProducts,
} from './ProductsState';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { getDefaultAxiosConfig } from '../../utils/axiosConfig';

// Maximum URL length to stay within browser limits
const MAX_SAFE_URL_LENGTH = 2000;

export default class ProductsBloc {
    private readonly stateSubject: BehaviorSubject<ProductsState>;
    private weClapp: WeClappService;
    private wooCommerce: WooCommerceService;

    constructor() {
        this.stateSubject = new BehaviorSubject( new ProductsState() );
        this.weClapp      = new WeClappService();
        this.wooCommerce  = new WooCommerceService();
    }

    // Utility method to create URL-safe chunks
    private createUrlSafeChunks = (items: string[], initialChunkSize: number, endpointPath: string): string[][] => {
        // Start with initial chunk size
        let result: string[][] = [];
        let baseUrlLength = WooCommerceService.getCptxEndpointUrl(endpointPath).length;
        
        // Additional URL characters (like '?skus=' or '?articleIds=')
        let paramPrefix = endpointPath.includes('?') ? '&' : '?';
        let paramName = endpointPath.includes('batchDelete') ? 'skus=' : 'articleIds=';
        let additionalChars = (paramPrefix + paramName).length;
        
        // First try with the initial chunk size
        let chunks = _.chunk(initialChunkSize, items);
        
        // Then check each chunk and split further if needed
        for (let chunk of chunks) {
            if (chunk.length === 0) continue;
            
            // For delete endpoint, we need to quote each SKU
            let urlParams = chunk.map(item => `"${item}"`).join(',');
                
            // Calculate the total URL length
            let urlLength = baseUrlLength + additionalChars + urlParams.length;
            
            if (urlLength <= MAX_SAFE_URL_LENGTH) {
                // This chunk is fine, add it
                result.push(chunk);
                continue;
            }
            
            // This chunk is too big, split it in half and try again
            let halfSize = Math.floor(chunk.length / 2);
            let firstHalf = chunk.slice(0, halfSize);
            let secondHalf = chunk.slice(halfSize);
            
            // Recursively add the smaller chunks
            result = result.concat(
                this.createUrlSafeChunks(firstHalf, firstHalf.length, endpointPath),
                this.createUrlSafeChunks(secondHalf, secondHalf.length, endpointPath)
            );
        }
        
        return result;
    }

    loadArticleData = async ( articleNumber: string, fetchBy: ProductSettingsFetchByMode = "sku", articleType: ProductSettingsArticleType = "variantArticle" ) => {
        this.setCurrentArticleNumber( articleNumber );
        this.setLoadingState( 'articleNumber', true );

        let res: any;

        if ( articleType === "article" ) {
            res = await this.weClapp.loadArticle( articleNumber, fetchBy );
        }

        if ( articleType === "variantArticle" ) {
            res = await this.weClapp.loadVariantArticle( articleNumber, fetchBy );
        }

        res = res?.result;

        this.setWeClappData( res[0] ?? [] );
        this.setLoadingState( 'articleNumber', false );
        return res[0];
    };

    deleteArticle = async ( weClappData: any | undefined ) => {
        if ( _.isUndefined( weClappData ) ) {
            weClappData = this.currentState.weClappData;
        }

        this.setLoadingState( 'articleNumber', true );

        await this.wooCommerce.deleteProduct( this.currentState.wooCommerceData.id );

        this.setLoadingState( 'articleNumber', false );
    };

    syncArticle = async ( weClappData: any | undefined ) => {
        if ( _.isUndefined( weClappData ) ) {
            weClappData = this.currentState.weClappData;
        }

        this.setLoadingState( 'articleNumber', true );
        let wooCommerceData = WooCommerceService.transformWeClappToWooCommerceData( weClappData );

        let product: any | undefined;
        /**
         * WooCommerce has found a product & "SKIP" is selected.
         * => Skip sync!
         */
        if ( _.isObject( this.currentState.wooCommerceData ) && this.currentState.settingsState.handleExistingProducts === 'skip' ) {
            toast.error( "Produkt existiert bereits – Übersprungen!" );
        }


        /**
         * WooCommerce has found a product & "UPDATE" is selected.
         * => Overwrite product!
         */
        if ( _.isObject( this.currentState.wooCommerceData ) && this.currentState.settingsState.handleExistingProducts === 'update' ) {
            // wooCommerceData.short_description = "updated product";

            // @ts-ignore
            product = await this.wooCommerce.updateProduct( this.currentState.wooCommerceData.id, wooCommerceData );
        }

        /**
         * WooCommerce hasn't found a product.
         * => Sync to WooCommerce!
         */
        if ( !_.isObject( this.currentState.wooCommerceData ) ) {

            // Single Article
            if ( !_.isUndefined( weClappData.articleNumber ) ) {
                product = await this.wooCommerce.postProduct( wooCommerceData );
            }

            // Variant Article
            if ( !_.isUndefined( weClappData.variantArticleNumber ) ) {
                product = await this.wooCommerce.postVariantArticle( weClappData.id );
            }

        }

        // If no action was taken (product == undefined)
        // Only update wooCommerceData if action was taken.
        if ( !_.isUndefined( product ) ) {
            this.setWooCommerceData( product );
        }

        this.setLoadingState( 'articleNumber', false );

        return product;
    };

    deleteBatchArticles = async ( articleNumbers: string[] ) => {
        this.setLoadingState( 'articleNumber', true );

        let SHORTEN_IMPORT = false;
        // Use the dynamic chunking method instead of fixed size
        let articleNumberChunks = this.createUrlSafeChunks(articleNumbers, 250, 'products/batchDelete');
        let currChunkIndex = 1;
        let totalArticleSkusToDelete: string[] = [];
        let totalArticlesToDeleted = 0;
        let totalArticlesDeleted = 0;
        let totalChunks = articleNumberChunks?.length ?? 0;
        let failedChunks: {chunkIndex: number, skus: string[]}[] = [];

        for ( let chunk of articleNumberChunks ) {
            if ( SHORTEN_IMPORT && currChunkIndex > 2 ) {
                console.warn( `🛑️ Manually stopped chunk delete.` );
                break;
            }

            let articlesAfterFilter = chunk.length;

            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            totalArticlesToDeleted += articlesAfterFilter;

            let skus = chunk.map(sku => `"${sku}"`).join( ',' );
            totalArticleSkusToDelete = totalArticleSkusToDelete.concat( skus.split( ',' ) );

            toast.info( `⌛️ Deleting chunk ${currChunkIndex}/${totalChunks}.` );
            console.log( `⌛️ Deleting chunk ${currChunkIndex}/${totalChunks}.` );

            try {
                let result: AxiosResponse = await axios.get(
                    WooCommerceService.getCptxEndpointUrl( `products/batchDelete?skus=${skus}` ),
                    getDefaultAxiosConfig()
                );

                let deleteProductIds = result?.data;

                console.log( deleteProductIds );
                if ( typeof deleteProductIds === "undefined" ) {
                    throw new Error('Delete product IDs undefined');
                }

                totalArticlesDeleted += deleteProductIds.length;

                toast.success( `🔥 Deleted chunk ${currChunkIndex}/${totalChunks}: ${deleteProductIds.length ?? "0"} articles.` );
                console.info( `🔥Deleted chunk ${currChunkIndex}/${totalChunks}.` );

            } catch (error) {
                toast.error( `❌ Chunk delete failed for ${currChunkIndex}/${totalChunks}.` );
                console.error( `❌ Chunk delete failed for ${currChunkIndex}/${totalChunks}:`, error );
                failedChunks.push({
                    chunkIndex: currChunkIndex,
                    skus: chunk
                });
            }

            currChunkIndex++;
        }

        if (failedChunks.length > 0) {
            console.error('Failed chunks:', failedChunks);
        }

        toast.success(`🟢️ Deletion of ${totalChunks} chunks (${totalArticlesDeleted} articles) completed!`);
        
        if (failedChunks.length > 0) {
            toast.error(`❌ Failed to delete ${failedChunks.length} chunks`);
        }

        this.setLoadingState( 'articleNumber', false );
    };

    syncBatchArticles = async ( articleNumbers: string[], syncMode: ProductSettingsHandleExistingProducts = 'skip' ) => {
        this.setLoadingState( 'articleNumber', true );

        let SHORTEN_IMPORT                    = false;
        // Use the dynamic chunking method instead of fixed size
        let articleNumberChunks               = this.createUrlSafeChunks(articleNumbers, 200, `utils/importVariantArticles?updateExisting=${syncMode === 'update' ? 'true' : 'false'}`);
        let currChunkIndex                    = 1;
        let totalArticleIdsToImport: string[] = [];
        let totalArticleNrsToImport: string[] = [];
        let totalShopArticlesToImport         = 0;
        let totalVariantArticlesImported      = 0;
        let totalShopArticlesImported         = 0;
        let totalChunks                       = articleNumberChunks?.length ?? 0;

        for ( let chunk of articleNumberChunks ) {
            if ( SHORTEN_IMPORT && currChunkIndex > 2 ) {
                console.warn( `🛑️ Manually stopped chunk import.` );
                break;
            }

            let articlesAfterFilter = chunk.length;

            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            totalShopArticlesToImport += articlesAfterFilter;

            let articleIds          = chunk.join( ',' );
            totalArticleIdsToImport = totalArticleIdsToImport.concat( articleIds.split( ',' ) );

            toast.info( `⌛️ Importing chunk ${currChunkIndex}/${totalChunks}.` );
            console.log( `⌛️ Importing chunk ${currChunkIndex}/${totalChunks}.` );

            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/importVariantArticles?articleIds=${articleIds}&updateExisting=${syncMode === 'update'
                                                                                                                              ? 'true'
                                                                                                                              : 'false'}` ),
                getDefaultAxiosConfig()
            );

            let variantArticlesData = result?.data;

            console.log( variantArticlesData );
            if ( typeof variantArticlesData === "undefined" ) {
                toast.error( `❌ Chunk import failed for ${currChunkIndex}/${totalChunks}.` );
                console.log( `❌ Chunk import failed for ${currChunkIndex}/${totalChunks}.` );
                currChunkIndex++;
                return;
            }

            let importedVariantArticles    = variantArticlesData.length;
            let totalImportedChunkArticles = 0;

            for ( let importedVariantArticle of variantArticlesData ) {
                totalImportedChunkArticles += importedVariantArticle?.variants?.length ?? 0;
            }

            totalVariantArticlesImported += importedVariantArticles;
            totalShopArticlesImported += totalImportedChunkArticles;

            toast.success( `🟢️ Imported chunk ${currChunkIndex}/${totalChunks}: ${importedVariantArticles} variantArticles  (${totalImportedChunkArticles} articles).` );
            console.info( `🟢️ Imported chunk ${currChunkIndex}/${totalChunks}.` );
            console.log( `ℹ️️ Synced ${importedVariantArticles} variantArticles (${totalImportedChunkArticles} articles).` );
            console.debug( variantArticlesData );

            currChunkIndex++;
        }

        toast.success( `🟢️ Sync of ${totalChunks} chunks completed!` );

        this.setLoadingState( 'articleNumber', false );
    };

    loadWooCommerceProduct = async ( articleNumber: string ) => {
        this.setLoadingState( 'wooCommerceData', true );
        let wooCommerceData = await this.wooCommerce.loadArticle( articleNumber );

        this.setWooCommerceData( wooCommerceData[0] );

        if ( wooCommerceData.length <= 0 ) {
            this.setWooCommerceData( 'Produkt ist nicht in WooCommerce vorhanden.' );
        }

        this.setLoadingState( 'wooCommerceData', false );

        return wooCommerceData[0];
    };

    setSettings = ( key: string, value: any ) => {
        let currentState: ProductsState = this.currentState;
        currentState.settingsState[key] = value;
        this.pushState( currentState );

        return currentState;
    };

    setLoadingState = ( key: string, loading: boolean = true ) => {
        let currentState: ProductsState = this.currentState;
        currentState.loadingStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    setOpenState = ( key: string, loading: boolean = true ) => {
        let currentState: ProductsState = this.currentState;
        currentState.openStates[key]    = loading;
        this.pushState( currentState );

        return currentState;
    };

    setCurrentArticleNumber = ( articleNumber: string ) => {
        let currentState: ProductsState   = this.currentState;
        currentState.currentArticleNumber = articleNumber;
        this.pushState( currentState );

        return currentState;
    };

    setWeClappData = ( weClappData: any ) => {
        let currentState: ProductsState = this.currentState;
        currentState.weClappData        = weClappData;
        this.pushState( currentState );

        return currentState;
    };

    setWooCommerceData = ( wooCommerceData: any ) => {
        let currentState: ProductsState = this.currentState;
        currentState.wooCommerceData    = wooCommerceData;
        this.pushState( currentState );

        return currentState;
    };

    get currentState() {
        return this.getStateSubject().getValue();
    }

    pushState = ( newState: ProductsState ) => {
        this.getStateSubject().next( newState );
        return newState;
    };

    getStateSubject = () => {
        return this.stateSubject;
    };
}
