// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import UtilsState from './UtilsState';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { getDefaultAxiosConfig } from '../../utils/axiosConfig';

// Maximum URL length to stay within browser limits
const MAX_SAFE_URL_LENGTH = 2000;

export default class UtilsBloc {
	private readonly stateSubject: BehaviorSubject<UtilsState>;
	private weClapp: WeClappService;
	private wooCommerce: WooCommerceService;

	constructor() {
		this.stateSubject = new BehaviorSubject(new UtilsState());
		this.weClapp = new WeClappService();
		this.wooCommerce = new WooCommerceService();

		this.loadGenderAttributes();
		this.loadVariationAttributesLastUpdates();
		this.loadBrandAttributes();
	}

	// Utility method to create URL-safe chunks
	private createUrlSafeChunks = (items: string[], initialChunkSize: number, endpointPath: string): string[][] => {
		// Start with initial chunk size
		let result: string[][] = [];
		let baseUrlLength = WooCommerceService.getCptxEndpointUrl(endpointPath).length;
		
		// Additional URL characters (like '?skus=' or '?articleIds=')
		let paramPrefix = endpointPath.includes('?') ? '&' : '?';
		let paramName = endpointPath.includes('skus=') ? '' : 'skus=';
		let additionalChars = (paramPrefix + paramName).length;
		
		// First try with the initial chunk size
		let chunks = _.chunk(initialChunkSize, items);
		
		// Then check each chunk and split further if needed
		for (let chunk of chunks) {
			if (chunk.length === 0) continue;
			
			// All endpoints in UtilsBloc quote the items
			let urlParams = chunk.map(item => `"${item}"`).join(',');
			
			// Calculate the total URL length
			let urlLength = baseUrlLength + additionalChars + urlParams.length;
			
			if (urlLength <= MAX_SAFE_URL_LENGTH) {
				// This chunk is fine, add it
				result.push(chunk);
				continue;
			}
			
			// This chunk is too big, split it in half and try again
			let halfSize = Math.floor(chunk.length / 2);
			let firstHalf = chunk.slice(0, halfSize);
			let secondHalf = chunk.slice(halfSize);
			
			// Recursively add the smaller chunks
			result = result.concat(
				this.createUrlSafeChunks(firstHalf, firstHalf.length, endpointPath),
				this.createUrlSafeChunks(secondHalf, secondHalf.length, endpointPath)
			);
		}
		
		return result;
	}

	loadGenderAttributes = async () => {
		this.setLoadingState('loadGenderAttributes', true);

		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/getGenderTerms`),
			getDefaultAxiosConfig()
		);

		let genderAttributes: any = result?.data;

		this.setGenderAttributes(genderAttributes);

		this.setLoadingState('loadGenderAttributes', false);
	};

	updateGenderAttributes = async () => {
		this.setLoadingState('updateGenderAttributes', true);

		let genderAttributes: any = await this.wooCommerce.updateGenderAttributes();
		this.setGenderAttributes(genderAttributes);

		this.setLoadingState('updateGenderAttributes', false);
	};

	updateVariationAttributes = async () => {
		this.setLoadingState('updateVariationAttributes', true);

		let variationAttributes: any = await this.wooCommerce.updateVariantAttributeOptions();
		variationAttributes = JSON.parse(variationAttributes);

		let now = Date.now();
		let dateString = new Intl.DateTimeFormat('de', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		}).format(now);

		this.setVariationOptions(variationAttributes, dateString);

		this.setLoadingState('updateVariationAttributes', false);
	};

	loadBrandAttributes = async () => {
		this.setLoadingState('updateBrandAttributes', true);

		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/getBrandTermAttributes`),
			getDefaultAxiosConfig()
		);

		let brandAttributes: any = result?.data;
		this.setBrandAttributes(brandAttributes);

		this.setLoadingState('updateBrandAttributes', false);
	};

	updateBrandAttributes = async () => {
		this.setLoadingState('updateBrandAttributes', true);

		let brandAttributes: any = await this.wooCommerce.updateBrandAttributes();
		brandAttributes = brandAttributes.map((brand: any) => brand.name);
		this.setBrandAttributes(brandAttributes);

		this.setLoadingState('updateBrandAttributes', false);
	};

	loadVariationAttributes = async () => {
		this.setLoadingState('loadVariationAttributes', true);

		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/getVariantAttributeOptions`),
			getDefaultAxiosConfig()
		);

		let variantAttributeOptions: any = JSON.parse(result?.data);
		this.setVariationOptions(variantAttributeOptions);

		this.setLoadingState('loadVariationAttributes', false);
	};

	loadVariationAttributesLastUpdates = async () => {
		this.setLoadingState('loadVariationAttributes', true);

		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/getVariantAttributeOptionsLastUpdated`),
			getDefaultAxiosConfig()
		);

		let variantAttributeOptions: any = result?.data;
		this.setVariationOptions(null, variantAttributeOptions);

		this.setLoadingState('loadVariationAttributes', false);
	};

	transformSkusToWeClappIds = async (skusToTransform: string[] = []) => {
		this.setLoadingState('transformSkusToWeClappIds', true);

		let SHORTEN_IMPORT = false;
		// Use the dynamic chunking method instead of fixed size
		let skuChunks = this.createUrlSafeChunks(skusToTransform, 100, 'utils/convertArticleNumberIntoArticleId');
		let currChunkIndex = 1;
		let totalSkusToTransform: string[] = [];
		let totalSkusNeedToTransform = 0;
		let totalSkusTransformed = 0;
		let totalChunks = skuChunks?.length ?? 0;
		let allTransformedWeClappIds: string[] = [];

		for (let chunk of skuChunks) {
			if (SHORTEN_IMPORT && currChunkIndex > 2) {
				console.warn(`🛑️ Manually stopped chunk SKU transformation.`);
				break;
			}

			let skusAfterFilter = chunk.length;

			if (chunk.length === 0) {
				console.log(`❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.`);
				currChunkIndex++;
				continue;
			}

			totalSkusNeedToTransform += skusAfterFilter;

			let skus = chunk.map((sku) => `"${sku}"`).join(',');
			totalSkusToTransform = totalSkusToTransform.concat(skus.split(','));

			toast.info(`⌛️ Transforming chunk ${currChunkIndex}/${totalChunks}.`);
			console.log(`⌛️ Transforming chunk ${currChunkIndex}/${totalChunks}.`);

			let result: AxiosResponse = await axios.get(
				WooCommerceService.getCptxEndpointUrl(`utils/convertArticleNumberIntoArticleId?skus=${skus}`),
				getDefaultAxiosConfig()
			);

			let transformedWeClappIds = result?.data;

			console.log(transformedWeClappIds);
			if (typeof transformedWeClappIds === 'undefined') {
				toast.error(`❌ Chunk transformation failed for ${currChunkIndex}/${totalChunks}.`);
				console.log(`❌ Chunk transformation failed for ${currChunkIndex}/${totalChunks}.`);
				currChunkIndex++;
				return;
			}

			totalSkusTransformed += transformedWeClappIds.length;

			toast.success(`♋️ Transformed chunk ${currChunkIndex}/${totalChunks}: ${transformedWeClappIds.length} articles.`);
			console.info(`♋️ Transformed chunk ${currChunkIndex}/${totalChunks}.`);

			allTransformedWeClappIds = allTransformedWeClappIds.concat(transformedWeClappIds);

			currChunkIndex++;
		}

		toast.success(`🟢️ Transformation of ${totalChunks} chunks completed!`);
		console.log(allTransformedWeClappIds);
		this.setTransformedWeClappIds(allTransformedWeClappIds);

		this.setLoadingState('transformSkusToWeClappIds', false);
	};

	removeNewTags = async () => {
		this.setLoadingState('removeNewTags', true);
		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/removeNewTagFromAllProducts`),
			getDefaultAxiosConfig()
		);

		toast.success(`🟢️ Removed NEU-Tag from all articles.`);
		console.log(result?.data);

		this.setLoadingState('removeNewTags', false);
	};

	fixBaseProductsWithNoImages = async () => {
		this.setLoadingState('fixBaseProductsWithNoImages', true);
		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/fixNoMainProductImageWithVariantImages`),
			getDefaultAxiosConfig()
		);
		let res = result?.data;
		toast.success(`🟢️ Fixed ${res?.length ?? 0} product with no images.`);
		console.log(res);

		this.setLoadingState('fixBaseProductsWithNoImages', false);
	};

	filterSkusWithNoImages = async () => {
		if (this.currentState.skusToFilter.length <= 0) {
			toast.error(`❌ Bitte füge SKUs in das Filter-Feld ein.`);
			return;
		}

		this.setLoadingState('filterSkus', true);

		let SHORTEN_IMPORT = false;
		// Use the dynamic chunking method instead of fixed size
		let skuChunks = this.createUrlSafeChunks(this.currentState.skusToFilter, 100, 'utils/filterSkusWithNoImages');
		let currChunkIndex = 1;
		let totalSkusToFilter: string[] = [];
		let totalSkusNeedToFilter = 0;
		let totalSkusFiltered = 0;
		let totalChunks = skuChunks?.length ?? 0;
		let allFilteredSkus: string[] = [];

		for (let chunk of skuChunks) {
			if (SHORTEN_IMPORT && currChunkIndex > 2) {
				console.warn(`🛑️ Manually stopped chunk SKU filtering.`);
				break;
			}

			let skusAfterFilter = chunk.length;

			if (chunk.length === 0) {
				console.log(`❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.`);
				currChunkIndex++;
				continue;
			}

			totalSkusNeedToFilter += skusAfterFilter;

			let skus = chunk.map((sku) => `"${sku}"`).join(',');
			totalSkusToFilter = totalSkusToFilter.concat(skus.split(','));

			toast.info(`⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.`);
			console.log(`⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.`);

			let result: AxiosResponse = await axios.get(
				WooCommerceService.getCptxEndpointUrl(`utils/filterSkusWithNoImages?skus=${skus}`),
				getDefaultAxiosConfig()
			);

			let filteredSkus = result?.data;

			console.log(filteredSkus);
			if (typeof filteredSkus === 'undefined') {
				toast.error(`❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.`);
				console.log(`❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.`);
				currChunkIndex++;
				return;
			}

			totalSkusFiltered += filteredSkus.length;

			toast.success(`♋️ Filtered chunk ${currChunkIndex}/${totalChunks}: ${filteredSkus.length} articles.`);
			console.info(`♋️ Filtered chunk ${currChunkIndex}/${totalChunks}.`);

			allFilteredSkus = allFilteredSkus.concat(filteredSkus);

			currChunkIndex++;
		}

		toast.success(`🟢️ Filtering of ${totalChunks} chunks completed!`);
		console.log(allFilteredSkus);
		this.setFilteredSkus(allFilteredSkus);

		this.setLoadingState('filterSkus', false);
	};

	filterExistingSkus = async (keepExisting: boolean = true) => {
		if (this.currentState.skusToFilter.length <= 0) {
			toast.error(`❌ Bitte füge SKUs in das Filter-Feld ein.`);
			return;
		}

		this.setLoadingState('filterSkus', true);

		// Use the dynamic chunking method instead of fixed size
		let articleNumberChunks = this.createUrlSafeChunks(this.currentState.skusToFilter, 250, 'utils/filterOutExistingSkus');
		let currChunkIndex = 1;
		let totalChunks = articleNumberChunks?.length ?? 0;

		let filteredArticleNumbers: any[] = [];

		for (let chunk of articleNumberChunks) {
			if (chunk.length === 0) {
				console.log(`❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.`);
				currChunkIndex++;
				continue;
			}

			// Use join without additional quotes since our createUrlSafeChunks handles URL safety
			let skus = chunk.join(',');
			console.log(`⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.`);

			let result: AxiosResponse = await axios.get(
				WooCommerceService.getCptxEndpointUrl(`utils/filterOutExistingSkus?skus=${skus}`),
				getDefaultAxiosConfig()
			);
			let newArticleSkus = result?.data;

			if (!keepExisting) {
				newArticleSkus = _.difference(chunk, newArticleSkus);
			}

			console.log(newArticleSkus);
			if (typeof newArticleSkus === 'undefined') {
				toast.error(`❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.`);
				console.log(`❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.`);
				currChunkIndex++;
				return;
			}

			filteredArticleNumbers = _.concat(filteredArticleNumbers, newArticleSkus);
			toast.success(`🔥 Filtered chunk ${currChunkIndex}/${totalChunks}: ${newArticleSkus.length} articles remained.`);
			console.info(`🔥 Filtered chunk ${currChunkIndex}/${totalChunks}: ${newArticleSkus.length} articles remained.`);

			currChunkIndex++;
		}

		toast.success(`🟢️ Filtering of ${totalChunks} chunks completed!`);
		this.setFilteredSkus(filteredArticleNumbers);

		this.setLoadingState('filterSkus', false);
	};

	importSubBrands = async () => {
		this.setLoadingState('importSubBrands', true);
		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/importSubBrands`),
			getDefaultAxiosConfig()
		);
		let newSubBrands = result?.data;
		toast.success(`🟢️ ${newSubBrands.length} new sub brands imported.`);
		this.setLoadingState('importSubBrands', false);
	};

	setSubBrands = async () => {
		this.setLoadingState('setSubBrands', true);

		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/setSubBrands`),
			getDefaultAxiosConfig()
		);
		let updatedArticles = result?.data;
		toast.success(`🟢️ Sub-Brands for ${updatedArticles.length} articles set.`);
		console.log(updatedArticles);

		this.setLoadingState('setSubBrands', false);
	};

	updateBrandCategoryOrders = async () => {
		this.setLoadingState('updateBrandCategoryOrders', true);

		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/setBrandCategoryOrders`),
			getDefaultAxiosConfig()
		);
		let orderedCategories = result?.data;
		toast.success(`🟢️ Product menu_order updated for ${orderedCategories.length} categories.`);
		console.log(orderedCategories);

		this.setLoadingState('updateBrandCategoryOrders', false);
	};

	addSizeGuideLinksToDl = async () => {
		this.setLoadingState('addSizeGuideLinksToDl', true);

		let result: AxiosResponse = await axios.get(
			WooCommerceService.getCptxEndpointUrl(`utils/setSizeGuideLinksToDl`),
			getDefaultAxiosConfig()
		);
		let updatedArticles = result?.data;
		toast.success(`🟢️ Size guide links added to ${updatedArticles.length} articles.`);
		console.log(updatedArticles);

		this.setLoadingState('addSizeGuideLinksToDl', false);
	};

	setFilterColors = async () => {
		this.setLoadingState('setFilterColors', true);
		// repeat sending the request until no entries are returned from the API
		let returnedArticles = [];
		let failSafe = 1;
		do {
			let result: AxiosResponse = await axios.get(
				WooCommerceService.getCptxEndpointUrl(`utils/transformColorAttributeToFilterColor?page=${failSafe}`),
				getDefaultAxiosConfig()
			);
			returnedArticles = result?.data;
			toast.success(`🟢️ Set filer colors for ${returnedArticles.length} articles. (Page ${failSafe})`);
			console.log(returnedArticles);
			failSafe++;
			// debugger;
		} while (returnedArticles.length > 0 && failSafe < 50);

		this.setLoadingState('setFilterColors', false);
	};

	setVariationOptions = (variationOptions: any = {}, updatedString: string = '') => {
		let currentState: UtilsState = this.currentState;

		if (!_.isNull(variationOptions)) {
			currentState.variationAttributes.options = variationOptions;
		}

		if (!_.isEmpty(updatedString)) {
			currentState.variationAttributes.lastUpdated = updatedString;
		}

		this.pushState(currentState);

		return currentState;
	};

	setSkusToTransform = (skusToTransform: string[] = []) => {
		let currentState: UtilsState = this.currentState;

		currentState.skusToTransform = skusToTransform;

		this.pushState(currentState);

		return currentState;
	};

	setTransformedWeClappIds = (transformedIds: string[] = []) => {
		let currentState: UtilsState = this.currentState;

		currentState.transformedWeClappIds = transformedIds;

		this.pushState(currentState);

		return currentState;
	};

	setSkusToFilter = (skusToFilter: string[] = []) => {
		let currentState: UtilsState = this.currentState;

		currentState.skusToFilter = skusToFilter;

		this.pushState(currentState);

		return currentState;
	};

	setFilteredSkus = (filteredSkus: string[] = []) => {
		let currentState: UtilsState = this.currentState;

		currentState.filteredSkus = filteredSkus;

		this.pushState(currentState);

		return currentState;
	};

	setGenderAttributes = (genderAttributes: any) => {
		let currentState: UtilsState = this.currentState;
		currentState.genderAttributes = genderAttributes;
		this.pushState(currentState);

		return currentState;
	};
	setBrandAttributes = (brandAttributes: any) => {
		let currentState: UtilsState = this.currentState;
		currentState.brandAttributes = brandAttributes;
		this.pushState(currentState);

		return currentState;
	};

	setSettings = (key: string, value: any) => {
		let currentState: UtilsState = this.currentState;
		currentState.settingsState[key] = value;
		this.pushState(currentState);

		return currentState;
	};

	setLoadingState = (key: string, loading: boolean = true) => {
		let currentState: UtilsState = this.currentState;
		currentState.loadingStates[key] = loading;
		this.pushState(currentState);

		return currentState;
	};

	setOpenState = (key: string, loading: boolean = true) => {
		let currentState: UtilsState = this.currentState;
		currentState.openStates[key] = loading;
		this.pushState(currentState);

		return currentState;
	};

	get currentState() {
		return this.getStateSubject().getValue();
	}

	pushState = (newState: UtilsState) => {
		this.getStateSubject().next(newState);
		return newState;
	};

	getStateSubject = () => {
		return this.stateSubject;
	};
}
