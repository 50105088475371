import { BehaviorSubject } from 'rxjs';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import CustomersState, { CustomersSettingsFetchByMode } from './CustomersState';
import { toast } from 'react-toastify';
import _ from 'lodash';

export default class CustomersBloc {
    private readonly stateSubject: BehaviorSubject<CustomersState>;
    private weClapp: WeClappService;
    private wooCommerce: WooCommerceService;

    constructor() {
        this.stateSubject = new BehaviorSubject( new CustomersState() );
        this.weClapp      = new WeClappService();
        this.wooCommerce  = new WooCommerceService();
    }

    loadCustomerData = async ( customerNr: string, fetchBy: CustomersSettingsFetchByMode = "customerNumber" ) => {
        this.setCurrentCustomerNumber( customerNr );
        this.setLoadingState( 'customerNumber', true );

        let res: any = await this.weClapp.loadCustomer( customerNr, fetchBy );

        res = res?.result;
        if ( ( res?.length ?? 0 ) <= 0 ) {
            toast.error( "Kunde nicht gefunden – Abgebrochen!" );
            this.setLoadingState( 'customerNumber', false );
            return;
        }

        let customer = res[0];
        this.setWeClappData( customer );

        this.setLoadingState( 'customerNumber', false );
    };

    loadWooCommerceCustomer = async ( customerNr: string, fetchBy: CustomersSettingsFetchByMode = "customerNumber" ) => {
        this.setLoadingState( 'wooCommerceData', true );

        let customer: any = await this.wooCommerce.loadCustomer( customerNr, fetchBy );

        if ( customer === "false" || customer === false ) {
            this.setLoadingState( 'wooCommerceData', false );
            return;
        }

        this.setWooCommerceData( customer );

        this.setLoadingState( 'wooCommerceData', false );
    };

    syncCustomer = async ( customerNr: string, fetchBy: CustomersSettingsFetchByMode = "customerNumber" ) => {
        this.setLoadingState( 'wooCommerceData', true );
        this.setLoadingState( 'customerNumber', true );

        let customer: any;

        if ( _.isPlainObject( this.currentState.weClappData )
             && _.isPlainObject( this.currentState.wooCommerceData ) ) {
            customer = await this.wooCommerce.updateCustomer( customerNr, fetchBy );
        }
        else {
            customer = await this.wooCommerce.importCustomer( customerNr, fetchBy );
        }

        this.setWooCommerceData( customer );

        this.setLoadingState( 'wooCommerceData', false );
        this.setLoadingState( 'customerNumber', false );
    };

    setWeClappData = ( weClappData: any ) => {
        let currentState: CustomersState = this.currentState;
        currentState.weClappData         = weClappData;
        this.pushState( currentState );

        return currentState;
    };

    setWooCommerceData = ( wooCommerceData: any ) => {
        let currentState: CustomersState = this.currentState;
        currentState.wooCommerceData     = wooCommerceData;
        this.pushState( currentState );

        return currentState;
    };


    setSettings = ( key: string, value: any ) => {
        let currentState: CustomersState = this.currentState;
        currentState.settingsState[key]  = value;
        this.pushState( currentState );

        return currentState;
    };

    setLoadingState = ( key: string, loading: boolean = true ) => {
        let currentState: CustomersState = this.currentState;
        currentState.loadingStates[key]  = loading;
        this.pushState( currentState );

        return currentState;
    };

    setOpenState = ( key: string, loading: boolean = true ) => {
        let currentState: CustomersState = this.currentState;
        currentState.openStates[key]     = loading;
        this.pushState( currentState );

        return currentState;
    };

    setCurrentCustomerNumber = ( customerNumber: string ) => {
        let currentState: CustomersState   = this.currentState;
        currentState.currentCustomerNumber = customerNumber;
        this.pushState( currentState );

        return currentState;
    };

    get currentState() {
        return this.getStateSubject().getValue();
    }

    pushState = ( newState: CustomersState ) => {
        this.getStateSubject().next( newState );
        return newState;
    };

    getStateSubject = () => {
        return this.stateSubject;
    };
}
