import React, { Component, createRef } from 'react';
import {
    CgSpinner,
    FiCopy,
    IoCogOutline,
    RiBuilding4Line,
    RiFilterLine,
    RiTShirtLine,
    SiBrandfolder,
} from 'react-icons/all';
import { BlocBuilder } from 'react-blocbuilder';
import Button, { ButtonStyle } from '../../buttons/Button';
import BrandsBloc from '../../../blocs/BrandsBloc/BrandsBloc';
import BrandsState, { ManufacturerData } from '../../../blocs/BrandsBloc/BrandsState';
import SettingsBox from '../../SettingsBox/SettingsBox';
import SegmentToggle from '../../SegmentToggle/SegmentToggle';

class BrandsPage extends Component<any, any> {
    private bloc: BrandsBloc;
    private manufacturerRef: React.RefObject<any> = createRef();
    private brandNameRef: React.RefObject<any> = createRef();

    constructor( props: any ) {
        super( props );

        this.bloc = new BrandsBloc();
    }

    render() {
        return (
            <BlocBuilder
                initialValue={new BrandsState()}
                subject={this.bloc.getStateSubject()}
                builder={( currentState ) => {
                    return ( <div>
                        <div className="flex  flex-row">
                            <h2 className="text-3xl  font-bold">Marken verwalten</h2>

                            <div className="ml-auto">
                                <button className="flex  flex-row  items-center  |  space-x-3  |  cursor-pointer"
                                        onClick={() => this.bloc.setOpenState( 'settings', !( currentState.data.openStates?.settings ?? false ) )}>
                                    <span className="display-inlineblock  |  text-blue-600">
                                        {!( currentState.data.openStates?.settings ?? false ) && "Einstellungen"}
                                        {( currentState.data.openStates?.settings ?? false ) && "Schließen"}
                                    </span>
                                    <IoCogOutline size={30} color={"#3b82f6"}/>
                                </button>
                            </div>
                        </div>

                        {( currentState.data.openStates?.settings ?? true ) &&
                         <div className="py-3  px-4  |  mt-3  |  bg-gray-200  |  rounded-lg">
                             <h3 className="text-xl  font-medium  |  mb-4">
                                 Einstellungen
                             </h3>

                             <div className="flex  flex-row  |  space-x-5  |  mb-2">
                                 <SettingsBox
                                     title="Aktiv-Status"
                                     desc="Sollen aktive oder inaktive Artikel geladen werden?"
                                     className="border-r  border-gray-300"
                                 >
                                     <SegmentToggle
                                         options={{
                                             'active':   'Aktiv',
                                             'inactive': 'Inaktiv',
                                         }}
                                         value={currentState.data.settingsState.fetchActive} id={'fetchActive'}
                                         onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'fetchActive', val ); }}
                                     />
                                 </SettingsBox>

                                 <SettingsBox
                                     title="Im Shop verfügbar"
                                     desc="Sollen nur Artikel geladen werden die 'Im Shop verfügbar' sind?"
                                     className="border-r  border-gray-300"
                                 >
                                     <SegmentToggle
                                         options={{
                                             'availableInShop': 'Im Shop verfügbar',
                                             'all':             'Alle',
                                         }}
                                         value={currentState.data.settingsState.availableInShop} id={'availableInShop'}
                                         onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'availableInShop', val ); }}
                                     />
                                 </SettingsBox>

                                 <SettingsBox
                                     title="Format"
                                     desc="Sollen die Artikel mit ihrer Artikel-Nr. oder ihrer WeClapp-ID geladen werden?"
                                     className="border-r  border-gray-300"
                                 >
                                     <SegmentToggle
                                         options={{
                                             'sku': 'Artikel-Nr.',
                                             'id':  'WeClapp-ID',
                                         }}
                                         value={currentState.data.settingsState.loadProp} id={'loadProp'}
                                         onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'loadProp', val ); }}
                                     />
                                 </SettingsBox>

                                 <SettingsBox
                                     title="Laden anhand…"
                                     desc="Sollen die Artikel anhand ihrers Manufacturers oder des Marken-Felds geladen werden?"
                                     className="border-r  border-gray-300"
                                 >
                                     <SegmentToggle
                                         options={{
                                             'manufacturer': 'Manufacturer',
                                             'brand':  'Marke',
                                         }}
                                         value={currentState.data.settingsState.loadByProp} id={'loadByProp'}
                                         onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'loadByProp', val ); }}
                                     />
                                 </SettingsBox>
                             </div>
                         </div>}

                        <hr className="my-6"/>

                        <div className="flex  flex-row  |  space-x-4">
                            {currentState.data.settingsState.loadByProp === 'manufacturer' &&
                             <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                 <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                     <RiBuilding4Line size={20}/>
                                     <span>Manufacturers</span>
                                 </h3>

                                 <div>
                                     <select name="manufacturers" id="manufacturers"
                                             ref={this.manufacturerRef}
                                             className={`w-full  py-2  px-3  rounded-lg  border  border-solid  border-grey-600  |  mb-4`}>
                                         {currentState.data.manufacturers.map( ( manufacturer: ManufacturerData ) => <option
                                             key={manufacturer.id}
                                             value={manufacturer.name} data-id={manufacturer.id}>{manufacturer.name}</option> )}
                                     </select>
                                 </div>

                                 <div className="flex  flex-row  items-center  space-x-3">
                                     <Button
                                         style={ButtonStyle.Default}
                                         onClick={( e ) => this.bloc.loadManufacturers()}
                                         loading={( currentState.data.loadingStates?.loadManufacturers === true )}
                                     >
                                         Aktualisieren
                                     </Button>

                                     <Button
                                         style={ButtonStyle.Default}
                                         onClick={( e ) => this.bloc.loadWeClappManufacturerArticles( [this.manufacturerRef.current.value] )}
                                         loading={( currentState.data.loadingStates?.loadManufacturerArticles === true )}
                                     >
                                         WeClapp Artikel laden
                                     </Button>

                                     <Button
                                         style={ButtonStyle.Default}
                                         onClick={( e ) => this.bloc.loadWooCommerceBrandArticles( this.manufacturerRef.current.value )}
                                         loading={( currentState.data.loadingStates?.loadWooCommerceBrandArticles === true )}
                                     >
                                         WooCommerce Artikel laden
                                     </Button>
                                 </div>
                             </div>
                            }

                            {currentState.data.settingsState.loadByProp === 'brand' &&
                                <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <SiBrandfolder size={20}/>
                                        <span>Marke</span>
                                    </h3>

                                    <div
                                        className="input-group  |  flex  flex-col  |  px-3  py-2  |  border-2  border-gray-300  |  rounded-md  |  bg-white  |  focus-within:border-gray-400  |  mb-2">
                                        <input type="text" className="focus:outline-none  font-medium  |  w-full"
                                               defaultValue={"Beechfield"}
                                               id="loadByPropBrandName" ref={this.brandNameRef}/>
                                    </div>

                                    <p className="text-xxs  text-gray-400  |  mb-4">
                                        Groß-/Kleinschreibung muss beim Laden der WeClapp Artikel beachtet werden.
                                    </p>

                                    <div className="flex  flex-row  items-center  space-x-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.loadWeClappBrandArticles( this.brandNameRef.current.value )}
                                            loading={( currentState.data.loadingStates?.loadBrandArticles === true )}
                                        >
                                            WeClapp Artikel laden
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.loadWooCommerceBrandArticles( this.brandNameRef.current.value )}
                                            loading={( currentState.data.loadingStates?.loadWooCommerceBrandArticles === true )}
                                        >
                                            WooCommerce Artikel laden
                                        </Button>
                                    </div>
                                </div>
                            }

                            <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                <h3 className="flex  flex-row  items-center  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                    <div className="flex  flex-row  items-center  space-x-3">
                                        <RiTShirtLine size={20}/>
                                        <span>Artikel-Daten</span>
                                        {( ( currentState?.data?.articles?.length ?? 0 ) > 0 ) &&
                                         <span className={`text-sm`}>({currentState?.data?.articles?.length})</span>}
                                    </div>

                                    <div className="ml-auto  flex  flex-row  items-center  space-x-5">
                                        <div className={`cursor-pointer  text-blue-600  hover:text-blue-500
                                                        |  flex  flex-row  items-center  text-sm  space-x-2`}
                                             onClick={() => this.bloc.filterExistingArticles()}>
                                            {currentState?.data.loadingStates?.filterArticles && <CgSpinner size={20} className="spinner  animate-spin"/>}
                                            {!currentState?.data.loadingStates?.filterArticles && <RiFilterLine size={20}/>}
                                            <span>Existierende ausfiltern</span>
                                        </div>

                                        <div className={`cursor-pointer  text-blue-600  hover:text-blue-500
                                                        |  flex  flex-row  items-center  text-sm  space-x-2`}
                                             onClick={() => navigator.clipboard.writeText( currentState?.data?.articles.join( '\n' ) )}>
                                            <FiCopy size={20}/>
                                            <span>Kopieren</span>
                                        </div>
                                    </div>
                                </h3>

                                <div className="whitespace-pre-wrap  |  font-mono  text-xs  |  rounded-md  overflow-hidden  |  mb-3"
                                     style={{
                                         columnCount: 4,
                                     }}>
                                    {currentState?.data?.articles.join( '\n' )}
                                </div>
                            </div>
                        </div>
                    </div> );
                }}
            />
        );
    }
}

export default BrandsPage;
