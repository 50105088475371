import App from "./App";
import "./App.css";
import WooCommerceService from "../services/WooCommerceService";
import { IoCogOutline, IoLockClosedOutline } from "react-icons/all";
import Button, { ButtonStyle } from "./buttons/Button";
import { useState, useEffect } from "react";

export default function AuthWrapper() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const checkAuth = async () => {
    setIsLoading(true);
    setError(null);

    const response = await WooCommerceService.checkInitialAuth();
    if (!response.isAuthenticated) {
      setError(response.error || "Nicht authentifiziert. Bitte melden Sie sich an.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (isLoading) {
    return (
      <div id="app">
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
          <div className="px-5 py-4 rounded-lg bg-gray-200 w-1/3">
            <h3 className="flex flex-row items-center justify-center mb-4">
              <div className="w-10  h-10  rounded-full  bg-gray-300  flex  items-center  justify-center">
                <IoCogOutline size={40} className="animate-spin" />
              </div>
            </h3>
            <div className="text-center">
              Verbindung wird hergestellt ...
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div id="app">
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
          <div className="px-5 py-4 rounded-lg bg-gray-200 w-1/3">
            <h3 className="flex flex-row items-center justify-center mb-4">
              <div className="w-10 h-10 p-3 rounded-full bg-gray-300 flex items-center justify-center">
                <IoLockClosedOutline size={40} />
              </div>
            </h3>
            <div className="text-center">
              {error}
            </div>

            <div className="mt-4 flex flex-row flex-wrap justify-center  gap-3">
              <Button style={ButtonStyle.Default} onClick={() => checkAuth()}>
                Erneut versuchen
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <App />;
}
